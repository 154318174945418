import { ProductModalInfoOptionsListItem as ProductModalInfoOptionsListItemClass, ProductModalInfoOptionsListItemPrice } from './productModalInfoOptionsItem.module.css';
import { ProductOption } from '../../../../../../../types/types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React from 'react';

interface ProductModalInfoOptionsListItemProps extends ProductOption{
}

const ProductModalInfoOptionsListItem = ({ id, Name, Price }: ProductModalInfoOptionsListItemProps) => (
  <div key={id} className={ProductModalInfoOptionsListItemClass}>
    <FormControlLabel
      control={<Radio value={Name} required color="primary" name={Name} />}
      label={Name}
    />
    <div className={ProductModalInfoOptionsListItemPrice}>{`${Price} ₽`}</div>
  </div>
);

export default ProductModalInfoOptionsListItem;
