import { SectionFilters as SectionFiltersClass } from './sectionFilters.module.css';
import SectionFiltersContainer from './SectionFiltersContainer/sectionFiltersContainer';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';

interface SectionFiltersProps {
    sort: string;
    filters: {filter:string, pressed: boolean}[];
    toogleSort: () => void;
    currentFilters: string[];
    setFilters: () => void;
}

const SectionFilters = ({
  sort, toogleSort, filters, setFilters, currentFilters,
}: SectionFiltersProps) => (
  <nav className={SectionFiltersClass}>
    {filters.length > 0 && (
    <SectionFiltersContainer
      currentFilters={currentFilters}
      setFilters={setFilters}
      filters={filters}
    />
    )}
    <Select
      value={sort}
      variant="outlined"
      onChange={toogleSort}
      displayEmpty
    >
      <MenuItem value={1}>Популярные</MenuItem>
      <MenuItem value={2}>
        <ListItemText primary="Цена ⬇️" />
      </MenuItem>
      <MenuItem value={3}>
        <ListItemText primary="Цена ⬆️" />
      </MenuItem>
    </Select>
  </nav>
);

export default SectionFilters;
