import ProductModalInfoHeadingNameWeight from './ProductModalInfoHeadingNameWeight/productModalInfoHeadingNameWeight';
import {
  ProductModalInfoHeading as ProductModalInfoHeadingClass,
  ProductModalChips, ProductModalInfoHeadingParagraph, ProductModalChip, ProductModalPrice
} from './productModalInfoHeading.module.css';
import Paragraph from '../../../Blocks/Paragraph/paragraph';
import { CategoriesType, CategoryTimeConstrainType } from '../../../../types/types';
import { getCategoriesWithTimeConstrains } from '../../../ProductCard/productCard';
import { isArrayEmpty } from '../../../../Utils/utils';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import H2 from '../../../Blocks/H2/H2';
import H3 from '../../../Blocks/H3/H3';

interface ProductModalInfoHeadingProps {
    weight: number;
    price: number;
    name: string;
    description: string;
    isVegan: boolean;
    isFixed: boolean;
    isSpicy: boolean;
    categories: CategoriesType;
    categoryTimeConstrain: CategoryTimeConstrainType;
}

const ProductModalInfoHeading = ({
  name, weight,
  description, isVegan, isFixed, isSpicy,
  categoryTimeConstrain, categories, price
}: ProductModalInfoHeadingProps) => {
  const categoriesWithTimeConstrains = getCategoriesWithTimeConstrains(categories);

  const getTimeConstrainsChips = () => {
    if (!isArrayEmpty(categoriesWithTimeConstrains)) {
      return <Chip variant="outlined" className={ProductModalChip} label={`⏰ C ${categoriesWithTimeConstrains[0].TimeConstrain.From.replace(/(:00)$/g, '')} до ${categoriesWithTimeConstrains[0].TimeConstrain.To.replace(/(:00)$/g, '')}`} />;
    } if (categoryTimeConstrain) {
      return <Chip variant="outlined" className={ProductModalChip} label={`⏰ C ${categoryTimeConstrain.From.replace(/(:00)$/g, '')} до ${categoryTimeConstrain.To.replace(/(:00)$/g, '')}`} />;
    }
  };

  return (
    <div className={ProductModalInfoHeadingClass}>
      <ProductModalInfoHeadingNameWeight weight={weight} name={name} />
      <H3 className={ProductModalPrice}>{price + " ₽"}</H3>
      <Paragraph className={ProductModalInfoHeadingParagraph}>{description}</Paragraph>
      <li className={ProductModalChips}>
        {isVegan && <Chip variant="outlined" className={ProductModalChip} label="🌱 Вегетарианский" />}
        {isSpicy && <Chip variant="outlined" className={ProductModalChip} label="🔥 Острый!" />}
        {getTimeConstrainsChips()}
      </li>
    </div>
  );
};

export default ProductModalInfoHeading;
