import Categories from './Categories/categories';
import { HeaderWithCategoriesNotfixed, HeaderWithCategories as HeaderWithCategoriesClass } from './headerWithCategories.module.css';
import Header from '../Header/header';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import SmartBanner from './SmartBanner/smartBanner';

interface HeaderWithCategoriesProps {
  notfixed?: boolean;
  showCategories: boolean;
  location: boolean;
}

const HeaderWithCategories = ({ notfixed, showCategories, location }: HeaderWithCategoriesProps) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiCategories(sort: {fields: Order, order: ASC}) {
        nodes {
          Name
          id
          URL
        }
      }
    }`);

  return (
    <div className={notfixed ? HeaderWithCategoriesNotfixed : HeaderWithCategoriesClass}>
      <Header location={location} />
      <SmartBanner />
      <Categories
        location={location}
        show={showCategories}
        labels={data.allStrapiCategories.nodes}
      />
    </div>
  );
};

export default HeaderWithCategories;
