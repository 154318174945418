import React from 'react';
import { SmartBanner as SmartBannerClass, SmartBannerContainer, SmartBannerButton, SmartBannerImage, SmartBannerBodyImageContainer } from './smartBanner.module.css';
import ButtonWithIcon from '../../Blocks/ButtonWithIcon/buttonWithIcon';
import AppIcon from '../../../images/app_icon@2x.png';

const SmartBanner = () => (
    <a href='https://onelink.to/3pd97t' target="__blank" className={SmartBannerClass}>
        <div className={SmartBannerContainer}>
            <div className={SmartBannerBodyImageContainer}>
                <img src={AppIcon} className={SmartBannerImage} alt="App icon" />
                <p>Заказывать удобнее
                    в приложении!</p>
            </div>
            <ButtonWithIcon additionalClass={SmartBannerButton} type='Download' label='Скачать' />
        </div>
    </a>
);


export default SmartBanner;
