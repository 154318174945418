import UserLogo from './icons/user.svg';
import CartLogo from './icons/cart.svg';
import DownloadLogo from './icons/download.svg';
import MenuLogo from './icons/menu.svg';

import { ButtonWithIconContainer, ButtonWithIconLabel } from './buttonWithIcon.module.css';
import LinkTo from '../LinkTo/linkTo';
import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';

interface ButtonWithIconProps {
  label: string;
  onClick?: () => void;
  additionalClass?: string;
  href?: string;
  noleftmargin?: boolean;
  type: 'Cart' | 'User' | 'Menu' | "Download";
}

const useStyles = makeStyles(() => ({
  btn: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: (noleftmargin) => (noleftmargin ? '0px' : '30px'),
    border: 'solid 1px',
    cursor: 'pointer',
    borderRadius: '5px',
    padding: '7px 12px 7px 12px',
    borderColor: 'var(--light-grey-color)',
    color: 'var(--black-color)',
    ':hover': {
      opacity: '.8',
    },
  },
}));

function ButtonWithIcon({
  label, href, type, additionalClass, onClick, noleftmargin,
}: ButtonWithIconProps) {
  const classes = useStyles(!!noleftmargin);

  const Icon = () => {
    switch (type) {
      case 'Cart':
        return <CartLogo />;
      case 'User':
        return <UserLogo />;
      case 'Menu':
        return <MenuLogo />;
      case 'Download':
        return <DownloadLogo />;
      default:
        return <MenuLogo />;
    }
  };

  return (
    href
      ? (
        <LinkTo to={href}>
          <ButtonBase
            focusRipple
            className={additionalClass ? `${classes.btn} ${additionalClass}` : classes.btn}
          >
            <Icon />
            <span className={ButtonWithIconLabel}>{label}</span>
          </ButtonBase>
        </LinkTo>
      )
      : (
        <ButtonBase focusRipple onClick={onClick} className={additionalClass ? `${classes.btn} ${additionalClass}` : classes.btn}>
          <Icon />
          <span className={ButtonWithIconLabel}>
            {label}
            {' '}
          </span>
        </ButtonBase>
      )

  );
}

export default ButtonWithIcon;
