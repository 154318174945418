import HeaderLogoNumber from './HeaderLogoNumber/headerLogoNumber';
import { HeaderContainer, HeaderBorder, Header as HeaderClass } from './header.module.css';
import HeaderButtons from './HeaderButtons/headerButtons';
import strapiAPI from '../../Utils/api/strapiApi';
import React, { useContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/gatsby';
import Cookies from 'js-cookie';
import { AuthContext } from '../../Utils/Store/store';

const Header = ({ location }) => {
  const [cashback, setCashback] = useState(null);
  const [cashbackIsLoading, setcashbackIsLoading] = useState(false);
  const { authState, authDispatch } = useContext(AuthContext);
  const userId = Cookies.getJSON('user_id');

  useEffect(() => {
    const controller = new AbortController();

    if (userId && cashback == null) {
      (async () => {
        setcashbackIsLoading(true);
        await strapiAPI.post('/users-permissions/getuserbyid', {
          customerID: userId,
        }, {
          signal: controller.signal
        }).then((res) => {
          setcashbackIsLoading(false);
          setCashback(res.data.walletBalances[0].balance);
        })
          .catch((err) => {
            captureException(err);
            setcashbackIsLoading(false);
          });
      })();
    }
    () => {
      controller.abort()
    }
  }, []);

  return (
    <div className={HeaderContainer}>
      {/* <div className={HeaderBorder} /> */}
      <div className={HeaderClass}>
        <HeaderLogoNumber cashback={cashback} numberLabel="Доставка в Севастополе" number="+7-869-288-83-30" secondNumber="+7-978-996-30-30" />
        <HeaderButtons cashback={cashback} location={location} />
      </div>
    </div>
  );
};

export default Header;
