import { SectionGrid as SectionGridClass } from './sectionGrid.module.css';
import { Product } from '../../../types/types';
import ProductCard from '../../ProductCard/productCard';
import React from 'react';

interface SectionGridProps {
    products: Product[];
    timeConstrain: {};
}

const SectionGrid = ({ products, timeConstrain }: SectionGridProps) => (
  <div className={SectionGridClass}>
    {products.map((val) => <ProductCard categoryTimeConstrain={timeConstrain} product={val} key={`${val.Name}-${val.iikoID}`} />)}
  </div>
);

export default SectionGrid;
