import {
  HeaderButtons as HeaderButtonsClass,
  HeaderSearchbar,
  HeaderButtonsCart,
  HeaderButtonsButtonSelected,
} from './headerButtons.module.css';
import { AuthContext, Context } from '../../../Utils/Store/store';
import AuthModal from '../../AuthModal/authModal';
import ButtonWithIcon from '../../Blocks/ButtonWithIcon/buttonWithIcon';
import SearchBar from '../../Blocks/SearchBar/searchBar';
import useIsClient from '../../../Utils/hooks/useIsClient';
import CashbackBadge from '../../Blocks/CashbackBadge/cashbackBadge';
import React, { useContext, useEffect, useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chipStyle: {
    marginLeft: 30,
  },
});

const HeaderButtons = ({ location, cashback }) => {
  const { state } = useContext(Context);
  const { authState } = useContext(AuthContext);
  const matches = useMediaQuery('(max-width:600px)');
  const [userButtonIsClicked, changeUserButtonClick] = useState(false);
  const [hasMounted, setHasMounted] = React.useState(false);
  const { isClient, key } = useIsClient();

  const classes = useStyles();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const handleUserButtonClick = (e) => {
    e.stopPropagation();

    changeUserButtonClick(true);
  };

  const onClose = () => {
    changeUserButtonClick(false);
  };

  const prodsForSearch = state.products.filter((prod) => prod.Status.Status !== 'hide');

  const cartIsNotEmpty = state.totalPrice > 0;
  if (!isClient) return null;
  return (
    <div className={HeaderButtonsClass}>
      <Box width={matches ? '190px' : 'auto'} display="flex">
        <SearchBar location={location} className={HeaderSearchbar} searchData={prodsForSearch} searchKeys={['Name', 'mainingredients']} />
      </Box>
      <Hidden implementation="css" xsDown>
        <CashbackBadge cashback={cashback} marginLeft={30} isAuthd={authState.isAuthd} />
      </Hidden>
      <Hidden implementation="css" xsDown>
        <ButtonWithIcon onClick={!authState.isAuthd && handleUserButtonClick} href={authState.isAuthd && '/profile'} type="User" label={authState.isAuthd ? 'Профиль' : 'Войти'} />
      </Hidden>
      <Hidden implementation="css" xsDown>
        <ButtonWithIcon type="Cart" additionalClass={cartIsNotEmpty ? `${HeaderButtonsCart} ${HeaderButtonsButtonSelected}` : HeaderButtonsCart} label={cartIsNotEmpty ? `${state.totalPrice} ₽` : 'Корзина'} href="/cart" />
      </Hidden>
      {userButtonIsClicked && <AuthModal isOpen={userButtonIsClicked} onClose={onClose} />}
    </div>
  );
};

export default HeaderButtons;
