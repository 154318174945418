import React, { HTMLAttributes } from 'react'

import {H3 as H3Class} from './H3.module.css'

interface H3Props extends HTMLAttributes<HTMLElement>{
    className?: string;
}

const H3 = ({children, className}: H3Props) => {
    return (
        <h3 className={className ? `${className} ${H3Class}` : H3Class} >
            {children}
        </h3>
    )
}

export default H3
