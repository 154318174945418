import ProductModalInfoModifsList from './ProductModalInfoModifsList/productModalInfoModifsItem';
import { ProductModalInfoOptions as ProductModalInfoOptionsClass } from './productModalInfoModifs.module.css';
import { ProductOption } from '../../../../types/types';

import H3 from '../../../Blocks/H3/H3';
import React from 'react';

interface ProductModalInfoModifsProps {
    setModifsSelected: () => void;
    heading: string;
    groupOfMods?: [];
    mods?: [];
}

const ProductModalInfoModifs = ({
  setModifsSelected, groupOfMods, mods, heading, modifsSelected
}: ProductModalInfoModifsProps) => (
  <div className={ProductModalInfoOptionsClass}>
    <H3>{heading}</H3>
    <ProductModalInfoModifsList
      groupOfMods={groupOfMods}
      mods={mods}
      modifsSelected={modifsSelected}
      setModifsSelected={setModifsSelected}
    />
  </div>
);

export default ProductModalInfoModifs;
