import { ProductModalInfoHeadingweight as ProductModalInfoHeadingweightClass, ProductModalInfoH2, ProductModalInfoWeight } from './productModalInfoHeadingNameWeight.module.css';
import H2 from '../../../../Blocks/H2/H2';
import React from 'react';

interface ProductModalInfoHeadingNameWeightProps {
    name: string;
    weight: number;
}

const ProductModalInfoHeadingNameWeight = ({
  name,
  weight,
}: ProductModalInfoHeadingNameWeightProps) => (
  <div className={ProductModalInfoHeadingweightClass}>
    <H2 className={ProductModalInfoH2}>{name}</H2>
    {weight && <H2 className={ProductModalInfoWeight}>{`${weight} гр.`}</H2>}
  </div>

);

export default ProductModalInfoHeadingNameWeight;
