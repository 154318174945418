import { ProductCardPriceCurrent as ProductCardPriceCurrentClass } from './productCardPriceCurrent.module.css';
import React from 'react';

interface ProductCardPriceCurrentProps {
    price: number;
    hasOptions?: boolean;
}

const ProductCardPriceCurrent = ({ price, hasOptions }: ProductCardPriceCurrentProps) => (
  <div className={ProductCardPriceCurrentClass}>

    {<span>{`${price} ₽`}</span>}
  </div>
);

ProductCardPriceCurrent.defaultProps = {
  hasOptions: undefined,
};

export default ProductCardPriceCurrent;
