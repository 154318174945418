import ProductModalInfoHeading from './ProductModalInfoHeading/productModalInfoHeading';
import ProductModalImage from './ProductModalImage/productModalImage';
import { ProductModalInfo as ProductModalInfoClass, ProductModalInfoStepper } from './productModalInfo.module.css';
import ProductModalInfoModifs from './ProductModalInfoModifs/productModalInfoModifs';
import ProductModalOptionsContainer from './ProductModalOptionsContainer/productModalOptionsContainer';
import { CategoriesType, ProductOption } from '../../../types/types';
import { isArrayEmpty } from '../../../Utils/utils';

import Stepper from '../../Blocks/Stepper/stepper';
import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

interface ProductModalInfoProps {
    options?: ProductOption[];
    name: string;
    weight: number;
    groupOfMods: {};
    description: string;
    imageURL: {};
    optionSelected: string;
    price: number;
    isVegan: boolean;
    isFixed: boolean;
    isSpicy: boolean;
    handleOptionsClick: () => void;
    setOptionSelected: () => void;
    setModifsSelected: () => void;
    incrementQuantity: () => void;
    decrementQuantity: () => void;
    quantity: number;
    categories: CategoriesType;

}

const ProductModalInfo = ({
  options, groupOfMods, imageURL, name, weight, description,
  handleOptionsClick, setOptionSelected, optionSelected,
  setModifsSelected, isVegan, isFixed, isSpicy, modifsSelected,
  incrementQuantity, decrementQuantity, quantity, categoryTimeConstrain,
  categories, price
}: ProductModalInfoProps) => (
  <div className={ProductModalInfoClass}>
    {imageURL && <ProductModalImage imageSizes={imageURL} name={name} />}
    <ProductModalInfoHeading
      isVegan={isVegan}
      isFixed={isFixed}
      categories={categories}
      categoryTimeConstrain={categoryTimeConstrain}
      isSpicy={isSpicy}
      description={description}
      name={name}
      price={price}
      weight={weight}
    />
    {!isArrayEmpty(options) && (
      <ProductModalOptionsContainer
        handleOptionsClick={setOptionSelected}
        options={options}
        modifsSelected={modifsSelected}
        setModifsSelected={setModifsSelected}
        optionSelected={optionSelected}
      />
    )}
    {!isArrayEmpty(groupOfMods) && (
      <ProductModalInfoModifs
        setModifsSelected={setModifsSelected}
        modifsSelected={modifsSelected}
        options={options}
        heading="Ингредиенты"
        groupOfMods={groupOfMods}
      />
    )}
    <Hidden implementation="css" smUp>
      <Box mt={3} mb={3}>
        <Divider variant="fullWidth" light />
      </Box>
      <div className={ProductModalInfoStepper}>
        <Stepper
          onClickMinus={decrementQuantity}
          onClickPlus={incrementQuantity}
          label={quantity}
        />
      </div>
    </Hidden>
  </div>
);

export default ProductModalInfo;
