import {
  ModalBody, ModalDivider, ModalContainer as ModalContainerClass, ModalOverlay, Modal as ModalClass, ModalCenter,
} from './modal.module.css';
import { Context } from '../../../Utils/Store/store';
import Divider from '../Divider/divider';
import React, { HTMLAttributes, useContext } from 'react';
import Modal from 'react-modal';

interface ModalProps extends HTMLAttributes<HTMLElement>{
    isOpen: boolean;
    center?: boolean;
}

const ModalContainer = ({ children, isOpen, center }: ModalProps) => {
  const { state } = useContext(Context);
  Modal.setAppElement('#___gatsby');

  return (
  // preventScroll not in type declaration
    <Modal bodyOpenClassName={ModalBody} overlayClassName={ModalOverlay} onRequestClose={() => false} preventScroll shouldCloseOnOverlayClick={false} className={center ? `${ModalClass} ${ModalCenter}` : ModalClass} isOpen={state.isModalOpened === false ? false : isOpen}>
      <Divider className={ModalDivider} />
      <div className={ModalContainerClass} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
      <Divider className={ModalDivider} />
    </Modal>
  );
};

export default ModalContainer;
