import { FooterTopCol, FooterTopColHeading, FooterTopColItem } from './footerTopColumnsCol.module.css';
import LinkTo from '../../../../Blocks/LinkTo/linkTo';
import { ColumnObj } from '../footerTopColumns';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface FooterTopColumnsColProps {
  column: ColumnObj;
}

const FooterTopColumnsCol = ({ column, external }: FooterTopColumnsColProps) => {
  const [status, setStatus] = useState("Смотрим статус...");
  const [color, setColor] = useState("black");

  useEffect(() => {
    (async () => {
      const summary = await axios.get("https://ms8rzycwxy4w.statuspage.io/api/v2/status.json")
      if (summary.data && summary.data.status && summary.data.status.indicator) {
        const status = summary.data.status.indicator;
        if (status == "none") {
          setStatus("Все системы в норме");
          setColor("green");
        }
        if (status == "minor") {
          setStatus("Небольшие ошибки в системе, исправляем");
          setColor("orange");
        }
        if (status == "major") {
          setStatus("Проблемы в системе, работаем над их устранением");
          setColor("light-red");
        }
        if (status == "critical") {
          setStatus("Критические проблемы в системе, работаем над их устранением");
          setColor("red");
        }
      }
    })();
  }, [])

  return (
    <ul className={FooterTopCol}>
      <h3 className={FooterTopColHeading}>{column.heading}</h3>
      {column.items.map((val) => <LinkTo external={val.external} to={val.url ? val.url : '/'} key={val.id} className={FooterTopColItem}>{val.name}</LinkTo>)}
      <a target="__blank" style={{ color: color }} href='https://wantsushi2.statuspage.io/'>{status}</a>
    </ul>
  )
};

export default FooterTopColumnsCol;
