import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'gatsby';
import React from 'react';

interface CashbackBadgeProps {
    cashback?: number;
    isAuthd?: bool;
    marginLeft: number;
}

const useStyles = makeStyles({
  chipStyle: {
    marginLeft: (props) => props,
    backgroundSize: '600%',
    backgroundPosition: '0 0',
    cursor: 'pointer',
    backgroundImage: 'linear-gradient(-45deg, #FFD6B0, #EE7238, #FFC089)',
    animation: '$gradient 5s ease infinite',
  },
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '25%': {
      backgroundPosition: '50% 0',
    },
    '50%': {
      backgroundPosition: '90% 0',
    },
    '60%': {
      backgroundPosition: '60%',
    },
    '75%': {
      backgroundPosition: '40%',
    },
    '100%': {
      backgroundPosition: '0 0',
    },
  },
});

const CashbackBadge = ({ cashback, marginLeft, isAuthd }: CashbackBadgeProps) => {
  const classes = useStyles(marginLeft);

  const getChipLabel = () => {
    if (!cashback || cashback <= 0) {
      return '🍣 Кешбэк';
    }
    return `🍣 ${Math.floor(cashback)} ₽`;
  };

  const getTooltipLabel = () => {
    if (!cashback || cashback <= 0) {
      if (isAuthd) {
        return 'Здесь будет ваш кешбэк';
      }
      return 'Войдите на сайт чтобы посмотреть сколько у вас кешбэка';
    }
    return `У вас ${Math.floor(cashback)} 🍣 кешбэка!`;
  };

  return (
    <Link to="/promo/#4-Кешбэк">
      <Tooltip disableFocusListener arrow title={getTooltipLabel()}>
        <Chip className={classes.chipStyle} color="primary" label={getChipLabel()} />
      </Tooltip>
    </Link>
  );
};

export default CashbackBadge;
