import AuthModalFields from './AuthModalFields/authModalFields';
import { AuthModalButton } from './authModal.module.css';
import { AuthContext, Context } from '../../Utils/Store/store';
import { AUTH_USER } from '../../Utils/Constants/constants';
import strapiAPI from '../../Utils/api/strapiApi';
import ButtonProduct from '../Blocks/ButtonProduct/buttonProduct';
import React, {
  useState, useContext,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { captureException } from '@sentry/gatsby';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import { vkPixelCompleteRegistration } from '../../Utils/vkPixel';
import Recaptcha from 'react-recaptcha';
import axiosRetry from 'axios-retry';

interface AuthModalProps {
  isOpen: boolean;
}

const NO_VALUE_ADDED_ERROR = 'Нужно указать код, который пришел вам на телефон';
const RESEND_TIMEOUT = 360000;

const AuthModal = ({ isOpen, onClose }: AuthModalProps) => {
  const { authState, authDispatch } = useContext(AuthContext);
  const { state, dispatch } = useContext(Context);
  const [canResendSMS, setCanResendSMS] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [phoneIsValid, changePhoneValidness] = useState(null);
  const [telInputValue, changeTelInputValue] = useState('');
  const [telInputIsDisabled, disableTelInput] = useState(false);

  const [verificationStrapiID, setVerificationStrapiID] = useState(null);

  const [passwordInputIsOpen, changePasswordInputIsOpen] = useState(false);

  const [passIsValid, changePassValidness] = useState(null);
  const [passInputValue, changePassInputValue] = useState('');
  const [PassInputIsDisabled, disablePassInput] = useState(false);
  const [passErrorText, setPassErrorText] = useState(NO_VALUE_ADDED_ERROR);

  const [recapchaValid, setRecapchaValid] = useState(false);
  const [recapchaToken, setRecapchaToken] = useState(false);

  const onButtonClick = () => {
    if (!phoneIsValid) {
      validateTelInput();
    }

    if (phoneIsValid && !passIsValid) {
      validatePassInput();
    }
  };

  const onCaptchaExpired = (response) => {
    setRecapchaValid(false);
    setRecapchaToken(null);
  };

  const verifyCaptcha = async (res) => {
    setRecapchaValid(true);
    setRecapchaToken(res);
  };

  const validateTelInput = async () => {
    if (telInputValue.length > 0) {
      const valIsValid = /^(\+?7|8)9\d{9}$/.test(telInputValue.toString());
      let verifID;
      setIsLoading(true);

      // why and
      if (!valIsValid || !recapchaToken) {
        setIsLoading(false);
        changePhoneValidness(false);
      } else {
        await sendSMS(telInputValue, recapchaToken)
          .then((res) => {
            verifID = res.data.id.toString();
          })
          .catch((err) => captureException(err));

        setIsLoading(false);
        changePhoneValidness(true);
        disableTelInput(true);
        setVerificationStrapiID(verifID);
        changePasswordInputIsOpen(true);
        setCanResendSMS(false);
        setTimeout(() => setCanResendSMS(true), RESEND_TIMEOUT);
      }
    }
  };

  const validatePassInput = async () => {
    if (passInputValue.length > 0) {
      setIsLoading(true);

      axiosRetry(strapiAPI, { retries: 100, retryDelay: () => 5000 });

      await strapiAPI.post('/confirm-users/verifysmscode', {
        id: verificationStrapiID,
        code: passInputValue,
      })
        .then(async (res) => {
          if (res.data.status === 'success') {
            return strapiAPI.post('/users-permissions/createorupdate',
              {
                phone: telInputValue,
              });
          }
          return changePassValidness(false);
        })
        .then(async (res) => {
          authDispatch({ type: AUTH_USER, payload: res.data });
          changePassValidness(true);
          disablePassInput(true);
          setCompleted(true);
          setIsLoading(false);
          await strapiAPI.post('/users-permissions/getuserordersbyphone', {
            phone: telInputValue.replace('+', ''),
          }).then(res => {
            if (res.data.customersDeliveryHistory.some((val) => val.deliveryHistory.length !== 0)) {
              Cookies.set('user_is_new', 'false');
            } else {
              Cookies.set('user_is_new', 'true');
              vkPixelCompleteRegistration();
            }
          }).catch(err => {
            captureException(err);
          });
        })
        .catch((err) => {
          setIsLoading(false);
          changePassValidness(false);
          captureException(err);
        });
    } else {
      setIsLoading(false);
      changePassValidness(false);
      setPassErrorText(NO_VALUE_ADDED_ERROR);
    }
  };

  const handleResend = async () => {
    const valIsValid = /^(\+?7|8)9\d{9}$/.test(telInputValue.toString());
    let verifID;

    if (valIsValid && recapchaToken) {
      await sendSMS(telInputValue, recapchaToken)
        .then((res) => {
          verifID = res.data.id.toString();
        })
        .catch((err) => captureException(err));

      setVerificationStrapiID(verifID);
      setCanResendSMS(false);
      setTimeout(() => setCanResendSMS(true), RESEND_TIMEOUT);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!completed && isOpen}
      aria-labelledby="окно-авторизации"
      onClose={onClose}
    >
      <DialogTitle id="заголовок-в-окне-авторизации">Вход на сайт</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Войдите, чтобы мы могли приготовить вам заказ и начислить кешбэк.
        </DialogContentText>
        <AuthModalFields
          changeTelInputValue={changeTelInputValue}
          changePassInputValue={changePassInputValue}
          telInputIsDisabled={telInputIsDisabled}
          phoneIsValid={phoneIsValid}
          PassInputIsDisabled={PassInputIsDisabled}
          passIsValid={passIsValid}
          passErrorText={passErrorText}
          passwordInputIsOpen={passwordInputIsOpen}
          handleResend={handleResend}
          canResendSMS={canResendSMS}
          recapchaValid={recapchaValid}
        />
        <Recaptcha
          hl="ru"
          expiredCallback={onCaptchaExpired}
          verifyCallback={verifyCaptcha}
          sitekey="6LcuBRwjAAAAABin3lj-15wDZxlA9B8hEz75GIwB"
        />
      </DialogContent>
      <DialogActions>
        {recapchaValid && <ButtonProduct onClick={onButtonClick} isPressedLabel={<CircularProgress color="primary" size={20} />} isPressed={isLoading} className={AuthModalButton} label={!phoneIsValid ? 'Далее' : 'Войти'} />}
      </DialogActions>
    </Dialog>
  );
};

const sendSMS = (telInputValue, captchaToken) => strapiAPI.post('/confirm-users/confirmPhoneBySMS', {
  phone: telInputValue,
  captchaToken: captchaToken
});

export default AuthModal;
