import React from 'react'

import FooterTopColumns, { ColumnObj } from './FooterTopColumns/footerTopColumns'
import FooterTopLogoApp from './FooterTopLogoApp/footerTopLogoApp'

import {FooterTop as FooterTopClass} from './footerTop.module.css'
interface FooterTopProps {
    columns: ColumnObj[];
}

const FooterTop = ({columns}:FooterTopProps) => {
    return (
        <div className={FooterTopClass}>
            <FooterTopLogoApp />
            <FooterTopColumns columns={columns}/>
        </div>
    )
}

export default FooterTop
