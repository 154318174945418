import { logo } from './headerLogo.module.css';
import LinkTo from '../../../Blocks/LinkTo/linkTo';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeaderLogo = () => {
  const data = useStaticQuery(graphql`{
  allStrapiLogo {
    nodes {
      Name
      Photo {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`);
  return (
    <LinkTo title="На главную" to="/">
      <div className={logo}>
        <GatsbyImage
          image={data.allStrapiLogo.nodes[0].Photo.localFile.childImageSharp.gatsbyImageData}
          alt={data.allStrapiLogo.nodes[0].Name}
          className={logo}
        />
      </div>
    </LinkTo>
  );
};

export default HeaderLogo;
