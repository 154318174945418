import ProductModalInfoOptionsList from './ProductModalInfoOptionsList/productModalInfoOptionsList';
import { ProductModalInfoOptions as ProductModalInfoOptionsClass } from './productModalInfoOptions.module.css';
import { ProductOption } from '../../../../../types/types';

import H3 from '../../../../Blocks/H3/H3';
import React from 'react';

interface ProductModalInfoOptionsProps {
    options?: ProductOption[];
    handleOptionsClick: () => void;
}

const ProductModalInfoOptions = ({
  options,
  handleOptionsClick,
  setModifsSelected
}: ProductModalInfoOptionsProps) => (
  <div className={ProductModalInfoOptionsClass}>
    <H3>Опции</H3>
    <ProductModalInfoOptionsList
      handleOptionsClick={handleOptionsClick}
      options={options}
      setModifsSelected={setModifsSelected}
    />
  </div>
);

export default ProductModalInfoOptions;
