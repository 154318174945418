import { SectionSubcategories as SectionSubcategoriesClass } from './SectionSubcategories.module.css';
import React, { ChangeEvent } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

interface SectionSubcategoriesProps {
    subcategories: string[];
    setSubcategories: (e: ChangeEvent, newvalue: string) => void;
    currentSubcategory: string;
}

const useStyles = makeStyles(() => ({
  tab: {
    fontWeight: 400,
    color: 'var(--dark-grey-color)',
  },
}));

const SectionSubcategories = ({
  subcategories,
  currentSubcategory, setSubcategories,
}: SectionSubcategoriesProps) => {
  const classes = useStyles();

  return (
    <Tabs variant="scrollable" indicatorColor="primary" textColor="primary" value={currentSubcategory} className={SectionSubcategoriesClass} onChange={setSubcategories} aria-label="Подкатегории меню">
      {subcategories.map((val) => <Tab key={`subcategory-${val}`} className={classes.tab} label={val} />)}
    </Tabs>
  );
};

export default SectionSubcategories;
