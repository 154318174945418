import FooterTopLogo from './FooterTopLogo/footerTopLogo';
import FooterTopAppLinks from './FooterTopAppLinks/footerTopAppLinks';
import { FooterTopLogoApp as FooterTopLogoAppClass } from './footerTopLogoApp.module.css';
import FooterTopWorkingHours from './FooterTopWorkingHours/footerTopWorkingHours';
import FooterTopNumber from './FooterTopNumber/footerTopNumber';
import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  footerContact: {
    display: 'block',
    marginTop: '50px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  footerWorkingHoursContainer: {
    display: 'block',
    marginTop: '24px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
  },
}));

const FooterTopLogoApp = (props) => {
  const classes = useStyles();

  return (
    <div className={FooterTopLogoAppClass}>
      <FooterTopLogo />
      <Box className={classes.footerContact}>
        <FooterTopNumber label="Доставка в Севастополе" number="+7-869-288-83-30" secondNumber="+7-978-996-30-30" />
      </Box>
      <Box className={classes.footerWorkingHoursContainer}>
        <FooterTopWorkingHours />
      </Box>
      <FooterTopAppLinks />

    </div>
  );
};

export default FooterTopLogoApp;
