import { ProductModalInfoOptionsListItem as ProductModalInfoOptionsListItemClass, ProductModalInfoOptionsListItemPrice, ProductModalInfoOptionsListItemStepper } from './productModalInfoModifsItem.module.css';
import { ProductOption } from '../../../../../../types/types';
import Stepper from '../../../../../Blocks/Stepper/stepper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';

interface ProductModalInfoModifsListItemProps extends ProductOption{
}

const ProductModalInfoModifsListItem = ({
  id,
  Name, Price, setModifsSelected, modifsSelected,
}: ProductModalInfoModifsListItemProps) => {
  const [checked, setChecked] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setModifsSelected({
      ...modifsSelected,
      [Name]: { ...modifsSelected[Name], amount },
    });
  }, [amount]);

  useEffect(() => {
    if (checked && amount === 0) {
      setAmount(1);
      setModifsSelected({
        ...modifsSelected,
        [Name]: { iikoID: id, amount, price: Price },
      });
    }
  }, [checked]);

  useEffect(() => {
    if (Object.keys(modifsSelected).length === 0) {
      setChecked(false);
      setAmount(0);
    }
  }, [modifsSelected]);

  return (
    <div
      key={id}
      className={ProductModalInfoOptionsListItemClass}
    >

      <FormControlLabel
        control={
          checked && amount !== 0
            ? (
              <Stepper
                onClickMinus={() => amount > 0 && setAmount(amount - 1)}
                onClickPlus={() => amount < 5 && setAmount(amount + 1)}
                size="sm"
                className={ProductModalInfoOptionsListItemStepper}
                label={amount}
              />
            )
            : <Checkbox color="primary" name={Name} />
        }
        label={Name}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <div className={ProductModalInfoOptionsListItemPrice}>{`${Price} ₽`}</div>
    </div>
  );
};

export default ProductModalInfoModifsListItem;
