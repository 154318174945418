import HeaderLogo from './HeaderLogo/headerLogo';
import { HeaderLogoNumberContainer, HeaderLogoMenuButton } from './headerLogoNumber.module.css';
import HeaderNumber from './HeaderNumber/headerNumber';
import MenuButton from './icons/menu.svg';
import { AuthContext, Context } from '../../../Utils/Store/store';
import AuthModal from '../../AuthModal/authModal';
import ButtonWithIcon from '../../Blocks/ButtonWithIcon/buttonWithIcon';
import CashbackBadge from '../../Blocks/CashbackBadge/cashbackBadge';
import React, { useContext, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

interface HeaderLogoNumberProps {
  numberLabel: string;
  number: string;
  secondNumber: string;
  cashback: number;
}

const useStyles = makeStyles((themes) => ({
  listAccount: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    width: 280,
  },
  divider: {
    height: '50%',
  },
  menuButton: {
    'MuiBadge-colorPrimary': {
      backgroundColor: '#2e80ff',
    },
    display: 'block',
    marginRight: '15px',
    [themes.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const HeaderLogoNumber = ({ numberLabel, number, cashback, secondNumber }: HeaderLogoNumberProps) => {
  const [menuButtonClicked, setMenuButton] = useState(false);
  const { authState, authDispatch } = useContext(AuthContext);
  const { state, dispatch } = useContext(Context);
  const [userButtonIsClicked, changeUserButtonClick] = useState(false);

  const classes = useStyles();

  const handleUserButtonClick = (e) => {
    e.stopPropagation();

    changeUserButtonClick(true);
  };

  const onClose = () => {
    changeUserButtonClick(false);
  };

  const data = useStaticQuery(graphql`
    {
      allStrapiCategories(sort: {fields: Order, order: ASC}) {
        nodes {
          Name
          id
          URL
        }
      }
    }`);

  const sideList = (
    <div className={classes.list}>
      <List>
        <ListItem key="profile">
          <div className={classes.listAccount}>
            <ButtonWithIcon noleftmargin onClick={!authState.isAuthd && handleUserButtonClick} href={authState.isAuthd && '/profile'} type="User" label={authState.isAuthd ? 'Профиль' : 'Войти'} />
            <CashbackBadge cashback={cashback} />
          </div>
        </ListItem>
        {data.allStrapiCategories.nodes.map((node) => (
          <Link key={node.id} to={`/${node.URL}`}>
            <ListItem button>
              <ListItemText primary={node.Name} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <div className={HeaderLogoNumberContainer}>
      <Badge color="primary" className={classes.menuButton} badgeContent={typeof cashback === 'number' && Math.floor(cashback)} max={999}>
        <MenuButton onClick={() => setMenuButton(true)} />
      </Badge>
      <HeaderLogo />
      <Hidden smDown>
        <Divider className={classes.divider} variant="middle" orientation="vertical" />
        <HeaderNumber number={number} label={numberLabel} secondNumber={secondNumber} />
      </Hidden>

      <SwipeableDrawer
        open={menuButtonClicked}
        onClose={() => setMenuButton(false)}
        onOpen={() => setMenuButton(true)}
      >
        {sideList}
      </SwipeableDrawer>
      {userButtonIsClicked && <AuthModal isOpen={userButtonIsClicked} onClose={onClose} />}
    </div>
  );
};

export default HeaderLogoNumber;
