import { CategoriesButton as CategoriesButtonClass, CategoriesButtonCurrent } from './categoriesButton.module.css';
import LinkTo from '../../../Blocks/LinkTo/linkTo';
import React from 'react';

interface CategoriesButtonProps {
    label: string;
    href: string;
    pathname: string;
}

const CategoriesButton = ({ label, href, pathname }:CategoriesButtonProps) => (
  <LinkTo to={href} className={(pathname && pathname === href) ? `${CategoriesButtonClass} ${CategoriesButtonCurrent}` : CategoriesButtonClass}>
    {label}
  </LinkTo>
);

export default CategoriesButton;
