import InstaLogo from './icons/insta.svg';
import VKLogo from './icons/vk.svg';

import { FooterBottomSocialItem } from './footerBottomSocial.module.css';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';

interface FooterBottomSocialProps {

}

const FooterBottomSocial = (props) => (
  <div>
    <a target="_blank" rel="noreferrer noopener" href="https://vk.com/ho4u_susi" className={FooterBottomSocialItem}>
      <IconButton aria-label="VK"><VKLogo /></IconButton>
    </a>

    <a target="_blank" rel="noreferrer noopener" href="https://instagram.com/hochusushisevastopol" className={FooterBottomSocialItem}>
      <IconButton aria-label="Vkontakte"><InstaLogo /></IconButton>
    </a>
  </div>
);

export default FooterBottomSocial;
