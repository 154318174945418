import React from 'react'

import FooterTopColumnsTop from './FooterTopColumnsCol/footerTopColumnsCol'

import {FooterTopColumns as FooterTopColumnsClass} from './footerTopColumns.module.css'

export interface ColumnItemObj {
    name: string;
    id: number;
    url: string;
}
export interface ColumnObj {
    key: number;
    heading: string;
    items: ColumnItemObj[];
}

interface FooterTopColumns {
    columns: ColumnObj[];
}

const FooterTopColumns = ({columns}:FooterTopColumns) => {
    return (
        <div className={FooterTopColumnsClass}>
            {columns.map(val => <FooterTopColumnsTop key={val.key} column={val}/>)}
        </div>
    )
}

export default FooterTopColumns
