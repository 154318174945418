import FooterTop from './FooterTop/footerTop';
import FooterDivider from './FooterDivider/footerDivider';
import FooterBottom from './FooterBottom/footerBottom';

import { FooterContainer, Footer as FooterClass } from './footer.module.css';
import React from 'react';

const columns = [
  {
    key: 0,
    heading: 'О нас',
    items: [
      { name: 'О нас', url: '/about', id: 1 },
      { name: 'Доставка', url: '/delivery', id: 2 },
      { name: 'Акции', url: '/promo', id: 3 },
      {
        name: 'Хочу выбрать суши', url: 'https://t.me/chooseyoursushi_bot', external: true, id: 4,
      },
    ],
  },
  // {
  //   key: 1,
  //   heading: 'Получить помощь',
  //   items: [
  //     { name: 'Наш блог', id: 1 },
  //     { name: 'Наш блог', id: 2 },
  //     { name: 'Наш блог', id: 3 },
  //     { name: 'Наш блог', id: 4 },
  //     { name: 'Наш блог', id: 5 }],
  // },
];

const Footer = (props) => (
  <div className={FooterContainer}>
    <div className={FooterClass}>
      <FooterTop columns={columns} />
      <FooterDivider />
      <FooterBottom />
    </div>
  </div>
);

Footer.propTypes = {

};

export default Footer;
