import SearchIcon from './icons/search.svg';
import { SearchContext } from '../../../Utils/Store/store';
import { ADD_SEARCH_RESULTS } from '../../../Utils/Constants/constants';
import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Fuse from 'fuse.js/dist/fuse.basic.min';
import queryString from 'query-string';
import { navigate } from '@reach/router';

interface SearchBarProps {
    searchData: [],
    searchKeys: string[];
    className?: string;
}

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #eaeaea',
    '&:hover': {
      opacity: 0.8,
    },
    marginLeft: 0,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(24 + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchBar = ({
  searchData, searchKeys, className, location,
}: SearchBarProps) => {
  const { searchState, searchDispatch } = useContext(SearchContext);
  const [searchInput, setSearchInput] = useState('');
  const [userIsWriting, setUserIsWriting] = useState(false);
  const [querySearchParameter, setQuerySearchParameter] = useState(null);
  const classes = useStyles();

  const handleSearch = (query) => {
    setSearchInput(query);

    if (!query.length) {
      navigate(location.pathname);
      return searchDispatch({ type: ADD_SEARCH_RESULTS, payload: [] });
    }

    const fuse = new Fuse(searchData, {
      keys: searchKeys,
    });

    navigate(`${location.pathname}?q=${query}`);

    const result = fuse.search(query);

    return searchDispatch({ type: ADD_SEARCH_RESULTS, payload: result.map((val) => val.item) });
  };

  useEffect(() => {
    if (location) {
      const parameter = queryString.parse(location.search);

      if (parameter && parameter.q) {
        setSearchInput(parameter.q);
        setQuerySearchParameter(parameter.q);

        const fuse = new Fuse(searchData, {
          keys: searchKeys,
        });

        const result = fuse.search(parameter.q);

        return searchDispatch({ type: ADD_SEARCH_RESULTS, payload: result.map((val) => val.item) });
      }
    }
  }, []);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        type="search"
        placeholder="Искать..."
        value={searchInput}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>

  );
};

export default SearchBar;
