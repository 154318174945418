import React from 'react'

import FooterBottomCopyright from './FooterBottomCopyright/footerBottomCopyright'
import FooterBottomSocial from './FooterBottomSocial/footerBottomSocial'

import {FooterBottom as FooterBottomClass} from './footerBottom.module.css'

interface FooterBottomProps {

}

const FooterBottom = props => {
    return (
        <div className={FooterBottomClass}>
            <FooterBottomSocial />
            <FooterBottomCopyright />
        </div>
    )
}

export default FooterBottom
