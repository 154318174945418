import {
  FooterBottomCopyright as FooterBottomCopyrightClass,
  FooterBottomCopyrightAuthor,
} from './footerBottomCopyright.module.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footerBottomCopyrightItem: {
    margin: '0',
    marginLeft: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '25px',
    },
  },

}));

const FooterBottomCopyright = () => {
  const classes = useStyles();

  return (
    <div className={FooterBottomCopyrightClass}>
      {/* <p className={classes.footerBottomCopyrightItem}>Privacy Policy</p>
    <p className={classes.footerBottomCopyrightItem}>Terms</p> */}
      <p className={classes.footerBottomCopyrightItem}>
        <a target="_blank" style={{ display: 'flex', alignItems: 'center' }} rel="noreferrer noopener" href="https://artyomalekseev.com/">
          <span className={FooterBottomCopyrightAuthor}>Дизайн</span>
        </a>
      </p>
      <p className={classes.footerBottomCopyrightItem}>© 2022 Хочу Суши</p>
    </div>
  );
};

export default FooterBottomCopyright;
