import { Categories as CategoriesClass, CategoriesHidden } from './categories.module.css';
import CategoriesButton from './CategoriesButton/categoriesButton';
import React, { useEffect, useRef, useState } from 'react';
import { useScrollPosition } from '../../../Utils/useScroll/useScrollPosition';
import {
  CategoriesLeftbutton, CategoriesButtonHidden, CategoriesRightbuttonContainer, CategoriesLeftbuttonContainer, CategoriesRightbutton,
} from './categories.module.css';
import ScrollButton from '../../Blocks/ScrollButton/scrollButton';
interface CategoriesObject {
    Name: string;
    id: string;
    URL: string;
}

interface CategoriesProps {
    show: boolean;
    labels: CategoriesObject[];
}

const Categories = ({ labels, show, location }:CategoriesProps) =>{ 
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const categoriesRef = useRef(null);

  const scroll = (scrollOffset) => {
    if (categoriesRef.current !== undefined) {
      categoriesRef.current.scrollLeft += scrollOffset;
    }
  };

  const handleHorizontalScroll = () => {
    const scrollWidth = categoriesRef.current.scrollWidth - categoriesRef.current.clientWidth;
    const scrollPosition = categoriesRef.current.scrollLeft;

    if (scrollPosition > 0) {
      setShowLeftButton(true);
    } else {
      setShowLeftButton(false);
    }

    if (scrollPosition >= scrollWidth) {
      setShowRightButton(false);
    } else {
      setShowRightButton(true);
    }
  };

  return (
  <div ref={categoriesRef} onScroll={handleHorizontalScroll} className={show ? CategoriesClass : CategoriesHidden}>
     <div className={showLeftButton
          ? CategoriesLeftbuttonContainer
          : CategoriesButtonHidden}
        >
          <ScrollButton className={CategoriesLeftbutton} type="left" onClick={() => scroll(-300)} />
      </div>
      <div className={showRightButton
          ? CategoriesRightbuttonContainer
          : CategoriesButtonHidden}
        >
          <ScrollButton className={CategoriesRightbutton} type="right" onClick={() => scroll(300)} />
        </div>
    {labels.map((val) => <CategoriesButton pathname={location && location.pathname} key={val.id} href={`/${val.URL}`} label={val.Name} />)}
  </div>
)};

export default Categories;
