import ProductCardDescription from './ProductCardDescription/productCardDescription';
import ProductCardHeading from './ProductCardHeading/productCardHeading';

import { ProductCardText as ProductCardTextClass } from './productCardText.module.css';
import React from 'react';

interface ProductCardTextProps {
    description: string;
    name: string;
    weight?: number;
    IsVegan?: boolean;
    IsSpicy?: boolean;
}

const ProductCardText = ({
  description, weight, name, IsSpicy, IsVegan,
}:ProductCardTextProps) => (
  <div className={ProductCardTextClass}>
    <ProductCardHeading IsSpicy={IsSpicy} IsVegan={IsVegan} weight={weight} name={name} />
    <ProductCardDescription description={description} />
  </div>
);

export default ProductCardText;
