import React from 'react'

import {ProductCardPriceOld as ProductCardPriceOldClass} from './productCardPriceOld.module.css'

interface ProductCardPriceOldProps {
    price: number;
}

const ProductCardPriceOld = ({price}: ProductCardPriceOldProps) => {
    return (
        <div className={ProductCardPriceOldClass}>
            {price + " ₽"}
        </div>
    )
}

export default ProductCardPriceOld
