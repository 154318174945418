import { ProductCardPrice as ProductCardPriceClass } from './productCardPrice.module.css';
import ProductCardPriceCurrent from './ProductCardPriceCurrent/productCardPriceCurrent';
import ProductCardPriceOld from './ProductCardPriceOld/productCardPriceOld';
import React from 'react';

interface ProductCardPriceProps {
    currentPrice: number;
    hasOptions?: boolean;
    oldPrice?: number;
}

const ProductCardPrice = ({
  currentPrice, oldPrice, hasOptions,
}: ProductCardPriceProps) => (
  <div className={ProductCardPriceClass}>
    <ProductCardPriceCurrent hasOptions={hasOptions} price={currentPrice} />
    {oldPrice && <ProductCardPriceOld price={oldPrice} />}
  </div>
);

ProductCardPrice.defaultProps = {
  hasOptions: undefined,
  oldPrice: undefined,
};

export default ProductCardPrice;
