import { H2 as H2Class } from './H2.module.css';
import React, { HTMLAttributes } from 'react';

interface H2Props extends HTMLAttributes<HTMLElement>{
    className?: string;
}

const H2 = ({ children, className }: H2Props) => (
  <h2 className={className ? `${H2Class} ${className}` : H2Class}>
    {children}
  </h2>
);

export default H2;
