import { Stepper as StepperClass, StepperLabelBG, StepperLabelSM } from './stepper.module.css';
import StepperButton from './StepperButton/stepperButton';
import React from 'react';

interface StepperProps {
    label: number;
    size?: 'bg' | 'sm';
    className?: string;
    onClickPlus?: (e?: Event) => void;
    onClickMinus?: (e?: Event) => void;
}

const Stepper = ({
  label, size, onClickMinus, onClickPlus, className,
}: StepperProps) => (
  <div className={className ? `${StepperClass} ${className}` : StepperClass}>
    <StepperButton onClick={onClickMinus} size={size} label="-" />
    <span className={size === 'sm' ? StepperLabelSM : StepperLabelBG}>{label}</span>
    <StepperButton onClick={onClickPlus} size={size} label="+" />
  </div>
);

Stepper.defaultProps = {
  size: 'bg',
};

export default Stepper;
