import React from 'react'

import {FooterDivider as FooterDividerClass} from  './footerDivider.module.css'

const FooterDivider = props => {
    return (
        <div className={FooterDividerClass}>
            
        </div>
    )
}

export default FooterDivider
