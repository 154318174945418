import CloseIcon from './icons/close.svg';

import {
  ProductModalHeader as ProductModalHeaderClass, ProductModalHeaderClose, ProductModalHeaderCloseIcon, ProductModalHeaderH3,
} from './productModalHeader.module.css';
import { Context } from '../../../Utils/Store/store';
import { MODAL_OPENED } from '../../../Utils/Constants/constants';
import H3 from '../../Blocks/H3/H3';
import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

interface ProductModalHeaderProps {
    heading: string;
}

const useStyles = makeStyles(() => ({
  iconButton: {
    marginLeft: '-12px',
  },
}));

const ProductModalHeader = ({ heading }: ProductModalHeaderProps) => {
  const { state, dispatch } = useContext(Context);
  const classes = useStyles();

  const handleCloseClick = () => {
    dispatch({ type: MODAL_OPENED, payload: false });
  };

  return (
    <div className={ProductModalHeaderClass}>
      <div className={ProductModalHeaderClose}>
        <IconButton focusRipple onClick={handleCloseClick} className={`ProductModalHeaderCloseIcon ${classes.iconButton}`}>
          <CloseIcon />
        </IconButton>
      </div>
      <H3 className={ProductModalHeaderH3}>{heading}</H3>
    </div>
  );
};

export default ProductModalHeader;
