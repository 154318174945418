import { ButtonProduct as ButtonProductClass, ButtonProductPressed } from './buttonProduct.module.css';
import Button from '@material-ui/core/Button';
import React from 'react';

interface ButtonProductProps {
    isPressed?: boolean;
    isDisabled?: boolean;
    onClick?: (e?: Event) => void;
    label: string;
    isPressedLabel?: string;
    className?: string;
    disableElevation?: boolean;
}

const ButtonProduct = ({
  isPressed, onClick, className, isPressedLabel, label, disableElevation, isDisabled,
}: ButtonProductProps) => {
  const generatedClassName = className ? `${className} ${ButtonProductClass}` : ButtonProductClass;

  return (
    <Button
      disableElevation={disableElevation}
      disabled={isPressed || isDisabled}
      type="submit"
      variant="contained"
      color="primary"
      onClick={onClick}
      className={isPressed ? `${generatedClassName} ${ButtonProductPressed}` : generatedClassName}
    >
      {isPressed ? isPressedLabel : label}
    </Button>
  );
};

ButtonProduct.defaultProps = {
  isPressed: false,
  isPressedLabel: 'Уже в корзине!',
  disableElevation: true,
};

export default ButtonProduct;
