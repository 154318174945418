import React from 'react'
import {ProductCardHeadingName as ProductCardHeadingNameClass, ProductCardHeadingNameTooltip} from  './productCardHeadingName.module.css'
import H3 from '../../../../Blocks/H3/H3'

interface ProductCardHeadingNameProps {
    name: string;
    IsSpicy?: boolean;
    IsVegan?: boolean;
}

const ProductCardHeadingName = ({name, IsSpicy, IsVegan}:ProductCardHeadingNameProps) => {
    return (
        <H3 className={ProductCardHeadingNameClass}>
            {name.trim()}
            {IsSpicy && <span className={ProductCardHeadingNameTooltip} title="Остро!">🌶</span> }
            {IsVegan && <span className={ProductCardHeadingNameTooltip} title="Для вегетарианцев">🌱</span> }
        </H3>
    )
}

export default ProductCardHeadingName
