import React from 'react'

import ProductCardHeadingWeight from './ProductCardHeadingWeight/productCardHeadingWeight'
import ProductCardHeadingName from './ProductCardHeadingName/productCardHeadingName'

import {ProductCardHeading as ProductCardHeadingClass} from './productCardHeading.module.css'

interface ProductCardHeadingProps {
    name: string;
    IsVegan?: boolean;
    IsSpicy?: boolean;
    weight?: number;
}

const ProductCardHeading = ({name, weight, IsVegan, IsSpicy}:ProductCardHeadingProps) => {
    return (
        <div className={ProductCardHeadingClass}>
            <ProductCardHeadingName IsVegan={IsVegan} IsSpicy={IsSpicy} name={name}/>
            {weight && <ProductCardHeadingWeight weight={weight}/>}
        </div>
    )
}

export default ProductCardHeading
