import ProductCardPrice from './ProductCardPrice/productCardPrice';
import { ProductCardBottom as ProductCardBottomClass, ProductCardBottomButton, ProductCardBottomButtonMobile } from './productCardBottom.module.css';
import ButtonProduct from '../../Blocks/ButtonProduct/buttonProduct';
import { Context } from '../../../Utils/Store/store';
import { Product } from '../../../types/types';
import { ADD_OR_INCREMENT_PRODUCT_TO_CART, REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID } from '../../../Utils/Constants/constants';
import Stepper from '../../Blocks/Stepper/stepper';
import { vkPixelAddToCart } from '../../../Utils/vkPixel';
import React, { useContext } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

interface ProductCardBottomProps {
    productData: Product;
    handleCardClick: (b: boolean) => void;
    isFixed?: boolean;
    categoryTimeConstrain: {};
}

const useStyles = makeStyles(() => ({
  fullwidth: {
    width: '100%',
  },
  mobileStepper: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const ProductCardBottom = ({
  productData, handleCardClick,
  isFixed, categoryTimeConstrain,
}: ProductCardBottomProps) => {
  const { state, dispatch } = useContext(Context);

  const classes = useStyles();

  const isProductInCart = state.cart.find((val) => val.id === productData.id);

  const handleClick = (e, product) => {
    e.stopPropagation();

    const prodDoesNotHaveOptions = (!productData.Options || productData.Options.length === 0
      || !Array.isArray(productData.Options));
    const prodDoesNotHaveModifs = (!productData.GroupOfMods || (!Array.isArray(productData.GroupOfMods) && !productData.GroupOfMods.id)
      || productData.GroupOfMods.length === 0);

    // If product has no options just add to cart
    if (prodDoesNotHaveOptions && prodDoesNotHaveModifs) {
      if (categoryTimeConstrain) {
        vkPixelAddToCart(productData.Price);
        return dispatch({
          type: ADD_OR_INCREMENT_PRODUCT_TO_CART,
          payload: { ...product, categoryTimeConstrain },
        });
      }

      vkPixelAddToCart(productData.Price);
      return dispatch({ type: ADD_OR_INCREMENT_PRODUCT_TO_CART, payload: product });
    }

    // Open product modal if there is product options
    handleCardClick(e);
  };

  const handlePlusClick = (e) => {
    e.stopPropagation();
    vkPixelAddToCart(productData.Price);
    dispatch({ type: ADD_OR_INCREMENT_PRODUCT_TO_CART, payload: productData });
  };

  const handleMinusClick = (e) => {
    e.stopPropagation();

    const id = productData.OptionSelectedID ? productData.OptionSelectedID : productData.iikoID;

    dispatch({ type: REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID, payload: id });
  };

  const hasOptions = Boolean(productData.Options && productData.Options.length);

  const getPriceLabelMobile = () => {
    if (hasOptions) {
      return `От ${productData.Price} ₽`;
    }
    return `${productData.Price} ₽`;
  };

  return (
    <div className={ProductCardBottomClass}>
      <ProductCardPrice
        currentPrice={productData.Price}
        hasOptions={hasOptions}
        oldPrice={productData.OldPrice}
      />
      <Hidden implementation="css" xsDown>
        {isProductInCart
          ? <Stepper size="bg" onClickMinus={handleMinusClick} onClickPlus={handlePlusClick} label={isProductInCart.productQuantity} />
          : <ButtonProduct className={ProductCardBottomButton} label="В корзину" isPressedLabel="Уже в корзине!" isPressed={isProductInCart} onClick={(e) => handleClick(e, productData)} />}
      </Hidden>
      <Hidden className={classes.fullwidth} implementation="css" smUp>
        {isProductInCart
          ? <Box className={classes.mobileStepper}><Stepper size="bg" onClickMinus={handleMinusClick} onClickPlus={handlePlusClick} label={isProductInCart.productQuantity} /></Box>
          : <Button variant="outlined" disableElevation className={ProductCardBottomButtonMobile} onClick={(e) => handleClick(e, productData)}>{`${productData.Price} ₽`}</Button>}
      </Hidden>
    </div>
  );
};

export default ProductCardBottom;
