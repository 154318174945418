import { ADD_SEARCH_RESULTS } from '../../../Utils/Constants/constants';
import { SearchContext } from '../../../Utils/Store/store';
import React, { HTMLAttributes, useContext } from 'react';
import { Link } from 'gatsby';

interface LinkToProps extends HTMLAttributes<HTMLElement> {
    to: string;
    className?: string;
    external: boolean;
}

const LinkTo = ({
  children, to, className, external,
}: LinkToProps) => {
  const { searchState, searchDispatch } = useContext(SearchContext);

  const handleClick = () => {
    searchDispatch({ type: ADD_SEARCH_RESULTS, payload: [] });
  };

  return !external ? (
    <Link className={className} onClick={handleClick} to={to}>
      {children}
    </Link>
  ) : (
    <a target="__blank" rel="noopener noreferrer" className={className} href={to}>
      {children}
    </a>
  );
};

export default LinkTo;
