import { MobileCartButton as MobileCartButtonClass, MobileCartButtonButton } from './mobileCartButton.module.css';
import { Context } from '../../../Utils/Store/store';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LinkTo from '../LinkTo/linkTo';

const useStyles = makeStyles((theme) => ({
  MobileCartButtonButton: {
    width: '100%',
    margin: '16px',
    height: '45px',
    fontSize: '16px',
  },
}));

const MobileCartButton = (props) => {
  const { state } = useContext(Context);

  const classes = useStyles();
  const cartIsNotEmpty = state.totalPrice > 0;

  return (
    <LinkTo to="/cart" className={MobileCartButtonClass}>
      <Button className={classes.MobileCartButtonButton} disableElevation variant="contained" color="primary">
        {cartIsNotEmpty ? `Заказ на ${state.totalPrice} ₽` : 'Корзина'}
      </Button>
    </LinkTo>
  );
};

export default MobileCartButton;
