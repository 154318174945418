import { FooterTopNumber as FooterTopNumberClass, FooterTopNumberLabel, FooterTopNumberNumber, FooterTopNumberSubLabel } from './footerTopNumber.module.css';
import { vkPixelContact } from '../../../../../Utils/vkPixel';
import React from 'react';

interface FooterTopNumberProps {
  number: string;
  secondNumber: string;
  label: string;
}

const FooterTopNumber = ({ number, label, subLabel, secondNumber }: FooterTopNumberProps) => {
  const onNumberClick = () => {
    vkPixelContact();
  };

  return (
    <div className={FooterTopNumberClass}>
      <h3 className={FooterTopNumberLabel}>{label}</h3>
      <a onClick={onNumberClick} href={`tel:${number.split('-').join('')}`} className={FooterTopNumberNumber}>
        {number}
      </a>
      <a onClick={onNumberClick} href={`tel:${secondNumber.split('-').join('')}`} className={FooterTopNumberNumber}>
        {secondNumber}
      </a>
    </div>
  );
};

export default FooterTopNumber;
