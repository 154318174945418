import * as styles from './stepperButton.module.css';
import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';

interface StepperButtonProps {
    label: string;
    onClick?: () => void;
    size: 'bg' | 'sm';
}

const useStyles = makeStyles(() => ({
  stepper: {
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    backgroundColor: '#eaeaea',
  },
}));

const StepperButton = ({ label, size, onClick }: StepperButtonProps) => {
  const classes = useStyles();

  return (
    <ButtonBase focusRipple onClick={onClick} className={`${classes.stepper} ${styles[`StepperButton${size.toUpperCase()}`]}`}>
      {label}
    </ButtonBase>
  );
};

export default StepperButton;
