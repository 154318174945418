import axios from 'axios';

const strapiAPI = axios.create({
  baseURL: process.env.GATSBY_STRAPI_URL,
  headers: {
  },
  data: {},
});

export default strapiAPI;
