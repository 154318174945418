const VKPIXEL = typeof window !== 'undefined' ? window.VK : null;

export const vkPixelAddToCart = (price) => {
  if (VKPIXEL) {
    VKPIXEL.Goal('add_to_cart', { value: price });
  }
};

export const vkPixelPurchase = (price) => {
  if (VKPIXEL) {
    VKPIXEL.Goal('purchase', { value: price });
  }
};

export const vkPixelCustomizeProduct = () => {
  if (VKPIXEL) {
    VKPIXEL.Goal('customize_product');
  }
};

export const vkPixelContact = () => {
  if (VKPIXEL) {
    VKPIXEL.Goal('contact');
  }
};

export const vkPixelPageView = () => {
  if (VKPIXEL) {
    VKPIXEL.Goal('page_view');
  }
};

export const vkPixelCompleteRegistration = () => {
  if (VKPIXEL) {
    VKPIXEL.Goal('complete_registration');
  }
};

export const vkPixelFindLocation = () => {
  if (VKPIXEL) {
    VKPIXEL.Goal('find_location');
  }
};

export const vkPixelInitiateCheckout = (sum) => {
  if (VKPIXEL) {
    VKPIXEL.Goal('initiate_checkout', { value: sum });
  }
};
