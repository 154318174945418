import ProductModalInfoOptions from './ProductModalInfoOptions/productModalInfoOptions';
import ProductModalInfoModifs from '../ProductModalInfoModifs/productModalInfoModifs';
import { isArrayEmpty } from '../../../../Utils/utils';
import React from 'react';

interface ProductModalOptionsContainerProps {
    handleOptionsClick: () => void;
    setModifsSelected: () => void;
    options: [];
    optionSelected: string;
}
const ProductModalOptionsContainer = ({
  handleOptionsClick,
  options, optionSelected, setModifsSelected, modifsSelected,
}: ProductModalOptionsContainerProps) => {
  const modsOfOptionSelected = optionSelected
  && options.find((val) => val.Name === optionSelected).Modificators;
  return (
    <div>
      <ProductModalInfoOptions
        setModifsSelected={setModifsSelected}
        handleOptionsClick={handleOptionsClick}
        options={options}
      />
      {optionSelected && Array.isArray(modsOfOptionSelected)
       && !isArrayEmpty(modsOfOptionSelected) && (
       <ProductModalInfoModifs
         mods={options.find((val) => val.Name === optionSelected).Modificators}
         heading="Ингредиенты"
         modifsSelected={modifsSelected}
         setModifsSelected={setModifsSelected}
       />
      )}
    </div>
  );
};

export default ProductModalOptionsContainer;

const handleModSelect = () => {

};
