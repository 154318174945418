import React from 'react'

import {ProductCardDescription as ProductCardDescriptionClass} from './productCardDescription.module.css'

interface ProductCardDescriptionProps {
    description: string;
}

const ProductCardDescription = ({description}:ProductCardDescriptionProps) => {
    return (
        <p className={ProductCardDescriptionClass}>
            {description}
        </p>
    )
}

export default ProductCardDescription
