import React, { HTMLAttributes } from 'react'

import {Paragraph as ParagraphClass} from './paragraph.module.css'

interface ParagraphProps extends HTMLAttributes<HTMLElement>{
    className?: string;
}

const Paragraph = ({children, className}: ParagraphProps) => {
    return (
        <p className={className ? `${ParagraphClass} ${className}` : ParagraphClass} >
            {children}
        </p>
    )
}

export default Paragraph
