import { ProductCardImage as ProductCardImageClass, ProductCardImageContainer } from './productCardImage.module.css';
import { CategoriesType, CategoryTimeConstrainType } from '../../../types/types';
import { isArrayEmpty } from '../../../Utils/utils';
import { getCategoriesWithTimeConstrains } from '../productCard';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

interface ProductCardImageProps {
    image: {};
    alt: string;
    categories: CategoriesType;
    categoryTimeConstrain: CategoryTimeConstrainType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
}));

const ProductCardImage = ({
  image, alt, categoryTimeConstrain,
  categories,
}:ProductCardImageProps) => {
  const classes = useStyles();
  const itemsWithTimeConstrains = getCategoriesWithTimeConstrains(categories);

  const getTimeCostrainsChips = () => {
    if (!isArrayEmpty(itemsWithTimeConstrains)) {
      return (
        <Chip
          classes={{ root: classes.root }}
          label={`C ${itemsWithTimeConstrains[0].TimeConstrain.From.replace(/(:00)$/g, '')} до ${itemsWithTimeConstrains[0].TimeConstrain.To.replace(/(:00)$/g, '')}`}
        />
      );
    } if (categoryTimeConstrain) {
      return (
        <Chip
          classes={{ root: classes.root }}
          label={`C ${categoryTimeConstrain.From.replace(/(:00)$/g, '')} до ${categoryTimeConstrain.To.replace(/(:00)$/g, '')}`}
        />
      );
    }
  };

  return (
    <div className={ProductCardImageContainer}>
      {getTimeCostrainsChips()}
      <GatsbyImage
        image={image.localFile.childImageSharp.gatsbyImageData}
        alt={alt}
        imgStyle={{ objectFit: 'contain' }}
        className={ProductCardImageClass}
      />
    </div>
  );
};

export default ProductCardImage;
