import React from 'react'

import {ProductCartHeadingWeight as ProductCartHeadingWeightClass} from './productCardHeadingWeight.module.css'

interface ProductCardHeadingWeightProps {
    weight: number;
}

const ProductCardHeadingWeight = ({weight}:ProductCardHeadingWeightProps) => {
    return (
        <small className={ProductCartHeadingWeightClass}>
            {weight + " гр."}
        </small>
    )
}

export default ProductCardHeadingWeight
