import { Divider as DividerClass } from './divider.module.css';
import React from 'react';

interface DividerProps {
    className?: string;
}

const Divider = ({ className }: DividerProps) => (
  <div className={className ? `${DividerClass} ${className}` : DividerClass} />
);

export default Divider;
