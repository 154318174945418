import { AuthModalFields as AuthModalFieldsClass } from './authModalFields.module.css';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

interface AuthModalFieldsProps {
}

const AuthModalFields = ({
  changeTelInputValue, changePassInputValue,
  telInputIsDisabled, phoneIsValid,
  PassInputIsDisabled, passIsValid,
  passErrorText, passwordInputIsOpen,
  handleResend, canResendSMS, recapchaValid
}: AuthModalFieldsProps) => {
  const handleTelInput = (e) => {
    changeTelInputValue(e.target.value);
  };

  const handlePassInput = (e) => {
    changePassInputValue(e.target.value);
  };

  return (
    <div className={AuthModalFieldsClass}>
      <TextField
        id="phone-number-authModal"
        onChange={handleTelInput}
        label="Номер телефона:"
        type="tel"
        inputMode="tel"
        disabled={telInputIsDisabled}
        placeholder="напр. 7983494932"
        helperText={phoneIsValid === false && 'Проверьте номер, должен начинаться с 7 или 8.'}
        error={phoneIsValid === false}
        fullWidth
        autoComplete="tel"
        margin="normal"
        variant="outlined"
      />
      <TextField
        id="pass-authModal"
        onChange={handlePassInput}
        label="Пароль из СМС:"
        type="number"
        fullWidth
        autoComplete="one-time-code"
        inputMode="numeric"
        margin="normal"
        disabled={PassInputIsDisabled}
        placeholder="напр. 7323"
        helperText={passIsValid === false && passErrorText}
        error={passIsValid === false}
        style={passwordInputIsOpen ? { display: 'block' } : { display: 'none' }}
        variant="outlined"
      />
      {passwordInputIsOpen && (
        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          marginBottom: '8px',
        }}
        >
          <Button onClick={handleResend} disabled={!canResendSMS} color="primary">{recapchaValid ? "Отправить ещё раз" : "Пройдите капчу, чтобы отправить еще раз"}</Button>
        </div>
      )}
    </div>
  );
};

export default AuthModalFields;
