import HeaderWithCategories from './HeaderWithCategories/headerWithCategories';
import Section from './Section/section';
import Footer from './Footer/footer';
import MobileCartButton from './Blocks/MobileCartButton/mobileCartButton';
import { ADD_CART_FROM_LOCALSTORAGE, ADD_PRODUCTS_TO_STATE, AUTH_USER } from '../Utils/Constants/constants';
import { AuthContext, Context, SearchContext } from '../Utils/Store/store';
import React, {
  HTMLAttributes, useContext, useEffect, useState,
} from 'react';
import Cookies from 'js-cookie';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import './layout.css';

interface LayoutProps extends HTMLAttributes<HTMLElement>{
  headerIsStatic?: boolean;
  showCategories?: boolean;
  headerWithNoCategories?: boolean;
  location?: string;
}

const useStyles = makeStyles({
  root: {
    zIndex: 10000,
  },
});

const Layout = ({
  children, headerIsStatic, showCategories, headerWithNoCategories, location,
}: LayoutProps) => {
  const { state, dispatch } = useContext(Context);
  const { authState, authDispatch } = useContext(AuthContext);
  const { searchState, searchDispatch } = useContext(SearchContext);
  let containerClass = headerIsStatic ? 'Container Container_headerstatic' : 'Container';
  containerClass = headerWithNoCategories ? `${containerClass} Container_headerwithnocategories` : containerClass;

  const cartIsNotEmpty = state.cart.length > 0;

  const classes = useStyles();

  const getAuthFromCookie = async () => {
    const userId = Cookies.getJSON('user_id');
    if (userId && userId !== 'undefined') {
      await authDispatch({ type: AUTH_USER, payload: userId });
    }
  };

  const updateLocalCache = async () => {
    const upd = localStorage.getItem('cache_update_version');

    if (upd !== '1') {
      localStorage.clear();
      localStorage.setItem('cache_update_version', '1');
    }
  };

  const getCartFromLocalStorage = async () => {
    if (localStorage.getItem('cart')) {
      const cart = JSON.parse(localStorage.getItem('cart'));

      if (cart.length > 0) {
        await dispatch({ type: ADD_CART_FROM_LOCALSTORAGE, payload: cart });
      }
    }
  };

  const data = useStaticQuery(graphql`{
  allStrapiProducts {
    nodes {
      Image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
      }
      IsSpicy
      isFixed
      IsVegan
      categories {
        Name
        TimeConstrain {
          From
          To
        }
      }
      LongDescription
      Name
      iikoID
      Price
      OldPrice
      Options {
        Name
        iikoID
        Price
        id
        Modificators {
          Name
          Price
          iikoModifID
        }
      }
      GroupOfMods {
        id
        Mod {
          Name
          Price
          id
          iikoModifID
        }
      }
      SmallDescription
      Status {
        Status
      }
      Weight
      id
      mainingredients {
        Name
      }
      subcategory {
        Name
      }
    }
  }
}
`);

  const loadProductToState = async () => {
    await dispatch({ type: ADD_PRODUCTS_TO_STATE, payload: data.allStrapiProducts.nodes });
  };

  useEffect(() => {
    (async function () {
      await updateLocalCache();
      await loadProductToState();
      await getCartFromLocalStorage();
      await getAuthFromCookie();
    }());
  }, []);

  const isSearchNotActivated = searchState.searchProducts.length === 0;

  return (

    <div
      onClick={() => dispatch({
        type: 'MODAL_OPENED',
        payload: state.isModalOpened === true && false,
      })}
      className={`Layout ${state.isModalOpened ? 'modal-opened' : ''}`}
    >
      <HeaderWithCategories
        location={location}
        showCategories={searchState.searchProducts.length > 0 || showCategories}
        notfixed={headerIsStatic}
      />
      {isSearchNotActivated
        ? (
          <main className={containerClass}>
            {children}
          </main>
        )
        : (
          <main className="Container">
            <Section withfilters heading="Результаты" products={searchState.searchProducts} />
            {cartIsNotEmpty && <MobileCartButton />}
          </main>
        )}
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  showCategories: true,
};

export default Layout;
