import { FooterTopWorkingHours as FooterTopWorkingHoursClass, FooterTopWorkingHoursLabel, FooterTopWorkingHoursNumber } from './footerTopWorkingHours.module.css';
import React from 'react';

const FooterTopWorkingHours = () => (
  <div className={FooterTopWorkingHoursClass}>
    <h3 className={FooterTopWorkingHoursLabel}>Рабочие часы:</h3>
    <div className={FooterTopWorkingHoursNumber}>вс-чт с 10:00 до 22:00</div>
    <div className={FooterTopWorkingHoursNumber}>пт-сб с 10:00 до 23:30</div>
  </div>
);

export default FooterTopWorkingHours;
