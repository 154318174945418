import { SectionContainer, SectionHeading, SectionHeadingH2, SectionLinkContainer } from './section.module.css';
import SectionFilters from './SectionFilters/sectionFilters';
import SectionGrid from './SectionGrid/sectionGrid';
import SectionSubcategories from './SectionSubcategories/SectionSubcategories';
import { CategoryTimeConstrainType, Product } from '../../types/types';
import { isArrayEmpty } from '../../Utils/utils';
import H2 from '../Blocks/H2/H2';

import Paragraph from '../Blocks/Paragraph/paragraph';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import ButtonText from '../Blocks/ButtonText/buttonText';
import Button from '@material-ui/core/Button';

interface SectionProps {
    heading: string;
    products: Product[];
    timeConstrain?: CategoryTimeConstrainType;
    link?: string;
    withfilters?: boolean;
    withsubcategories?: boolean;
}

const Section = ({
  heading, products, withfilters, withsubcategories,
  timeConstrain, link,
}: SectionProps) => {
  const [sort, handleSort] = useState(1);

  const [filters, setFilters] = useState([]);

  const [currentFilters, setCurrentFilters] = useState([]);

  const [currentSubcategory, setCurrentSubcategory] = useState(0);

  const [subcategories, setSubcategories] = useState([]);

  // идем по массиву и находим самые часто встречающийся, оставляем три.

  const createFilters = (prods: Product[]) => {
    const copy = prods.slice();

    const mapIngredients = [].concat.apply([], copy.filter((val) => val.mainingredients).map((val) => val.mainingredients && val.mainingredients.map((valu) => valu.Name)));

    if (mapIngredients.length === 0) {
      return 0;
    }

    // find occurences of ingredients
    const map = mapIngredients.reduce((p, c) => {
      p[c] = (p[c] || 0) + 1;
      return p;
    }, {});

    // return if only 1-3 ingredients exist
    if (map.length <= 4) {
      return setFilters(map);
    }

    // sort it
    const sorted = Object.keys(map).sort((a, b) => map[b] - map[a]);

    const sliced = sorted.slice(0, 4);

    return setFilters(sliced);
  };

  const sortByFilter = (prods: Product[]) => {
    let prodsArray = [];
    let filtered = [];

    // if subcategory selected filter by subcategory
    if (currentSubcategory && !isArrayEmpty(subcategories) && withsubcategories) {
      if (currentSubcategory === 0) {
        prodsArray = prods.slice();
      } else {
        prodsArray = prods
          .filter((val) => val.subcategory
            .some((valu) => valu.Name.trim() === subcategories[currentSubcategory]));
      }
    } else {
      prodsArray = prods.slice();
    }

    if (currentFilters === undefined || currentFilters.length === 0) {
      return prodsArray;
    }

    currentFilters.forEach((filter) => {
      filtered.push(...prodsArray
        .filter((prod) => prod.mainingredients
          .some((ing) => ing.Name === filter)));
    });

    if (filtered.length === 0) {
      return prodsArray;
    }

    filtered = filtered.filter((thing, index, self) => index === self.findIndex((t) => (
      t.id === thing.id
    )));

    return filtered;
  };

  const getSubcategories = (prods: Product[]) => {
    const products = prods.slice();

    // map all subcategories
    const subs = [].concat.apply([], products
      .map((val) => val.subcategory))
      .map((val) => val.Name.trim());

    if (!isArrayEmpty(subs)) {
      subs.unshift('Все');

      // remove duplicates and return
      setSubcategories([...new Set(subs)]);
    } else {
      setSubcategories([]);
    }
  };

  const toogleSort = (event) => {
    handleSort(event.target.value);
  };

  const sortArray = (sortType, prods) => {
    const copy = prods.slice();

    switch (sortType) {
      case 1:
      default:
        return copy;
      case 2:
        return copy.sort((a, b) => a.Price - b.Price);
      case 3:
        return copy.sort((a, b) => b.Price - a.Price);
    }
  };

  const handleSubcategoryChange = (event, newValue) => {
    setCurrentSubcategory(newValue);
  };

  const getProducts = () => {
    // filter array
    const filtered = sortByFilter(products);

    // sort array

    const filteredAndSorted = sortArray(sort, filtered);

    // return

    return filteredAndSorted;
  };

  useEffect(() => {
    if (withsubcategories) {
      createFilters(products);
      getSubcategories(products);
    }
  }, []);

  return (
    <div className={SectionContainer}>
      <div className={SectionHeading}>
        {link
          ? (
            <Link className={SectionLinkContainer} to={link}>
              <H2 className={SectionHeadingH2}>{heading}</H2>
              <Button color="primary" variant="text">Смотреть все</Button>
            </Link>
          )
          : <H2 className={SectionHeadingH2}>{heading}</H2>}
        {timeConstrain && <Paragraph>{`Доступны по будням с ${timeConstrain.From} до ${timeConstrain.To}`}</Paragraph>}
      </div>
      {(!isArrayEmpty(subcategories) && withsubcategories)
      && (
      <SectionSubcategories
        setSubcategories={handleSubcategoryChange}
        currentSubcategory={currentSubcategory}
        subcategories={subcategories}
      />
      )}
      {withfilters
      && (
      <SectionFilters
        currentFilters={currentFilters}
        setFilters={setCurrentFilters}
        filters={filters}
        toogleSort={toogleSort}
        sort={sort}
      />
      )}
      <SectionGrid timeConstrain={timeConstrain} products={getProducts()} />
    </div>
  );
};

Section.defaultProps = {
  link: null,
  timeConstrain: null,
  withfilters: false,
  withsubcategories: false,
};

export default Section;
