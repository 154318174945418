import { Link } from 'gatsby'
import React from 'react'
import LogoTrans from '../../../../../images/logo_trans.png';
import { FooterTopLogo as FooterTopLogoClass } from './footerTopLogo.module.css'

const FooterTopLogo = props => {
    return (
        <Link to="/">
            <img src={LogoTrans} className={FooterTopLogoClass} alt="Logo" />
        </Link>
    )
}

export default FooterTopLogo
