import { ProductModalFooter as ProductModalFooterClass, ProductModalFooterStepper, ProductModalFooterButton } from './productModalFooter.module.css';
import { Product } from '../../../types/types';

import { ADD_PRODUCT_TO_CART, MODAL_OPENED } from '../../../Utils/Constants/constants';
import { Context } from '../../../Utils/Store/store';
import ButtonProduct from '../../Blocks/ButtonProduct/buttonProduct';
import Stepper from '../../Blocks/Stepper/stepper';
import { ProductModalModif } from '../productModal';
import { vkPixelAddToCart } from '../../../Utils/vkPixel';
import React, { useContext } from 'react';
import { Hidden } from '@material-ui/core';

interface ProductModalFooterProps {
    product: Product;
    quantity: number;
    optionSelected: string;
    modifsSelected: ProductModalModif[],
    incrementQuantity: () => void;
    decrementQuantity: () => void;
}

const ProductModalFooter = ({
  product, quantity, incrementQuantity, decrementQuantity,
  optionSelected, modifsSelected, categoryTimeConstrain,
}: ProductModalFooterProps) => {
  const { dispatch } = useContext(Context);
  const quantityMoreThanOne = quantity > 1;

  const buttonLabel = quantityMoreThanOne ? `Добавить ${quantity} к заказу` : 'Добавить в корзину';

  const handleButtonClick = () => {
    // If product has no options
    if ((product.Options.length === 0 && !optionSelected) || !Array.isArray(product.Options)) {
      let productForSubmit;
      if (Object.keys(modifsSelected).length !== 0) {
        const modifs = Object.keys(modifsSelected)
          .map((val) => ({ ...modifsSelected[val], name: val }))
          .filter((val) => val.amount > 0);

        const modifsFullPrice = (modifs.length > 1)
          ? modifs.reduce((a, v) => a + (v.price * v.amount), 0)
          : modifs[0].price;

        productForSubmit = {
          ...product,
          categoryTimeConstrain,
          Price: product.Price + modifsFullPrice,
          productQuantity: quantity,
          modifsSelected: Object.keys(modifsSelected)
            .map((val) => ({ ...modifsSelected[val], name: val }))
            .filter((val) => val.amount > 0),
        };
      } else {
        // If product has no options and modifs
        productForSubmit = {
          ...product,
          categoryTimeConstrain,
          productQuantity: quantity,
        };
      }
      vkPixelAddToCart(productForSubmit.Price * quantity);
      dispatch({ type: ADD_PRODUCT_TO_CART, payload: productForSubmit });
      dispatch({ type: MODAL_OPENED, payload: false });

      // If product has options
    } else if (optionSelected && Object.keys(optionSelected).length !== 0) {
      let productForSubmit: Product;
      const prodOption = product.Options.find((val) => val.Name === optionSelected);
      const newProdName = `${product.Name} | ${optionSelected}`;

      // If product has options and modifs
      if (Object.keys(modifsSelected).length !== 0
      && Object.keys(modifsSelected).some((val) => modifsSelected[val].amount > 0)) {
        const modifs = Object.keys(modifsSelected)
          .map((val) => ({ ...modifsSelected[val], name: val }))
          .filter((val) => val.amount > 0);

        // Counting full modif price
        const modifsFullPrice = (modifs.length > 1)
          ? modifs.reduce((a, v) => a + (v.price * v.amount), 0)
          : modifs[0].price;

        productForSubmit = {
          ...product,
          categoryTimeConstrain,
          productQuantity: quantity,
          modifsSelected: modifs,
          Name: newProdName,
          Price: prodOption.Price + modifsFullPrice,
          OptionSelectedID: prodOption.iikoID,
        };
      } else {
        productForSubmit = {
          ...product,
          categoryTimeConstrain,
          productQuantity: quantity,
          Name: newProdName,
          Price: prodOption.Price,
          OptionSelectedID: prodOption.iikoID,
        };
      }
      vkPixelAddToCart(productForSubmit.Price * quantity);
      dispatch({ type: ADD_PRODUCT_TO_CART, payload: productForSubmit });
      dispatch({ type: MODAL_OPENED, payload: false });
    }
  };

  return (
    <div className={ProductModalFooterClass}>
      <Hidden implementation="css" xsDown>
        <Stepper
          className={ProductModalFooterStepper}
          onClickMinus={decrementQuantity}
          onClickPlus={incrementQuantity}
          label={quantity}
        />
      </Hidden>
      <ButtonProduct
        isPressed={product.Options.length > 0 && !optionSelected}
        isPressedLabel="Нужно выбрать опцию"
        label={buttonLabel}
        className={ProductModalFooterButton}
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default ProductModalFooter;
