import ProductModalFooter from './ProductModalFooter/productModalFooter';
import ProductModalHeader from './ProductModalHeader/productModalHeader';
import ProductModalInfo from './ProductModalInfo/productModalInfo';
import { Product } from '../../types/types';
import ModalContainer from '../Blocks/Modal/modal';
import { Context } from '../../Utils/Store/store';
import React, { useContext, useState } from 'react';

export interface ProductModalModif {
  amount: number;
  iikoID: string;
  price: number;
}
interface ProductModalProps{
    isOpen: boolean;
    product: Product;
    categoryTimeConstrain: {};
}

const ProductModal = ({ isOpen, product, categoryTimeConstrain }: ProductModalProps) => {
  const {
    Name, Options, Weight, LongDescription, SmallDescription,
    Image, GroupOfMods, isFixed, IsVegan, IsSpicy, Price, categories
  } = product;

  const { state } = useContext(Context);
  const isProductInCart = state.cart.find((val) => val.id === product.id);
  const [currentQuantity, changeCurrentQuality] = useState(isProductInCart ? isProductInCart.productQuantity : 1);
  const [optionSelected, setOptionSelected] = useState(null);
  const [modifsSelected, setModifsSelected] = useState({});

  const handleIncrementQuantity = () => {
    changeCurrentQuality(currentQuantity + 1);
  };

  const handleDecrementQuantity = () => {
    const value = currentQuantity === 1 ? 1 : currentQuantity - 1;
    changeCurrentQuality(value);
  };

  return (
    <ModalContainer isOpen={isOpen}>
      <ProductModalHeader heading={Name} />
      <ProductModalInfo
        groupOfMods={GroupOfMods}
        setOptionSelected={setOptionSelected}
        imageURL={Image}
        setModifsSelected={setModifsSelected}
        modifsSelected={modifsSelected}
        optionSelected={optionSelected}
        description={LongDescription || SmallDescription}
        options={Options}
        name={Name}
        price={Price}
        weight={Weight}
        isVegan={IsVegan}
        isFixed={isFixed}
        isSpicy={IsSpicy}
        incrementQuantity={handleIncrementQuantity}
        decrementQuantity={handleDecrementQuantity}
        categoryTimeConstrain={categoryTimeConstrain}
        categories={categories}
        quantity={currentQuantity}
      />
      <ProductModalFooter
        quantity={currentQuantity}
        optionSelected={optionSelected}
        incrementQuantity={handleIncrementQuantity}
        decrementQuantity={handleDecrementQuantity}
        product={product}
        categoryTimeConstrain={categoryTimeConstrain}
        modifsSelected={modifsSelected}
      />
    </ModalContainer>
  );
};

export default ProductModal;
