import { ProductModalImageContainer, ProductModalImage as ProductModalImageClass } from './productModalImage.module.css';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

interface ProductModalImageProps {
    imageSizes: {};
    name: string;
}

const ProductModalImage = ({ name, imageSizes }: ProductModalImageProps) => (
  <div className={ProductModalImageContainer}>
    <GatsbyImage
      image={imageSizes.localFile.childImageSharp.gatsbyImageData}
      alt={name}
      className={ProductModalImageClass}
    />
  </div>
);

export default ProductModalImage;
