import React from 'react'
import ArrowRightIcon from './icons/arrow-right.svg'
import ArrowLeftIcon from './icons/arrow-left.svg'

import {ScrollButton as ScrollButtonClass} from './scrollButton.module.css'

interface ScrollButtonProps {
    type: "right" | "left";
    onClick: () => void;
    className?: string;
}

const ScrollButton = ({type, onClick, className}: ScrollButtonProps) => {
    const Icon = () => {
        switch (type) {
            case "left":
                return <ArrowLeftIcon />
            case "right":
                return <ArrowRightIcon />
        }
    }

    return (
        <button onClick={onClick} className={className ? `${ScrollButtonClass} ${className}` : ScrollButtonClass}>
            <Icon />
        </button>
    )
}

export default ScrollButton
