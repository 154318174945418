import ProductModalInfoOptionsListItem from './ProductModalInfoOptionsItem/productModalInfoOptionsItem';
import { ProductModalInfoOptionsList as ProductModalInfoOptionsListClass } from './productModalInfoOptionsList.module.css';
import { ProductOption } from '../../../../../../types/types';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';

interface ProductModalInfoOptionsListProps {
    options?: ProductOption[];
    handleOptionsClick: () => void;
}

const ProductModalInfoOptionsList = ({
  options,
  handleOptionsClick,
  setModifsSelected,
}: ProductModalInfoOptionsListProps) => {
  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    handleOptionsClick(event.target.value);
    setModifsSelected({});
    setValue(event.target.value);
  };

  return (
    <RadioGroup onChange={handleChange} value={value} className={ProductModalInfoOptionsListClass}>
      {options && options.map((val) => (
        <ProductModalInfoOptionsListItem
          key={val.id}
          id={val.id}
          Name={val.Name}
          Price={val.Price}
        />
      ))}
    </RadioGroup>
  );
};

export default ProductModalInfoOptionsList;
