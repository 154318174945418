import IOSIcon from './icons/ios.svg';
import AndroidIcon from './icons/android.svg';

import { FooterTopApplinks as FooterTopApplinksClass } from './footerTopAppLinks.module.css';
import React from 'react';

const FooterTopAppLinks = (props) => (
  <div className={FooterTopApplinksClass}>
    <a target="__blank" href="https://apps.apple.com/us/app/%D1%85%D0%BE%D1%87%D1%83-%D1%81%D1%83%D1%88%D0%B8-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0/id6463722256">
      <IOSIcon />
    </a>

    <a target="__blank" href="https://play.google.com/store/apps/details?id=com.wantsushi.app">
      <AndroidIcon />
    </a>
  </div>
);

export default FooterTopAppLinks;
