import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { ChangeEvent } from 'react';

interface SectionFiltersContainerProps {
    filters: string[];
    setFilters: (e: ChangeEvent) => void;
    currentFilters: string[];
}

const SectionFiltersContainer = ({ filters, setFilters, currentFilters }: SectionFiltersContainerProps) => {
  const handleChange = (event) => {
    setFilters(event.target.value);
  };

  return (
    <Select
      value={currentFilters}
      onChange={handleChange}
      multiple
      displayEmpty
      variant="outlined"
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span>Ингредиенты</span>;
        }
        return (
          <div>
            {
                selected.map((value) => (
                  <Chip key={value} label={value} />
                ))
                }
          </div>
        );
      }}
    >
      {filters.map((name) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SectionFiltersContainer;
