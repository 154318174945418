import { Product } from '../types/types';

const dataLayer = typeof window !== 'undefined' ? window.dataLayer : null;

// Action when user open cart page
export const ecommerceOnPurchase = (promocodeNameUsed, cart) => {
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: 'TRX987',
          coupon: promocodeNameUsed || '',
        },
        products: cart.map((val: Product) => ({
          id: val.iikoID,
          name: val.Name,
          price: val.Price,
          quantity: val.productQuantity,
        })),
      },
    },
  });
};

// Action when user open cart page
export const ecommerceOnCartPage = (cart) => {
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: 'В корзине' },
        products: cart.map((val: Product) => ({
          id: val.iikoID,
          name: val.Name,
          price: val.Price,
          quantity: val.productQuantity,
        })),
      },
    },
  });
};

// Action when user open checkout page
export const ecommerceOnCheckoutPage = (cart) => {
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: 'Оформление заказа' },
        products: cart.map((val: Product) => ({
          id: val.iikoID,
          name: val.Name,
          price: val.Price,
          quantity: val.productQuantity,
        })),
      },
    },
  });
};

// Action when user open checkout page
export const ecommerceOnSuccessPage = (cart) => {
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: 'Заказ отправлен' },
        products: cart.map((val: Product) => ({
          id: val.iikoID,
          name: val.Name,
          price: val.Price,
          quantity: val.productQuantity,
        })),
      },
    },
  });
};

// Action when user opened product modal
export const ecommerceOnProductModalOpening = (price, name) => {
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    ecommerce: {
      detail: {
        products: [
          {
            name,
            price,
          },
        ],
      },
    },
  });
};
