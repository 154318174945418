import ProductCardText from './ProductCardText/productCardText';
import ProductCardImage from './ProductCardImage/productCardImage';
import ProductCardBottom from './ProductCardBottom/productCardBottom';

import { ProductCartImagetextContainer, ProductCard as ProductCardClass, ProductCardContainer } from './productCard.module.css';
import { Context } from '../../Utils/Store/store';
import { CategoriesType, Product } from '../../types/types';
import ProductModal from '../ProductModal/productModal';
import { MODAL_OPENED } from '../../Utils/Constants/constants';
import { ecommerceOnProductModalOpening } from '../../Utils/ecommerce';
import React, { useContext, useState } from 'react';
import { vkPixelCustomizeProduct } from '../../Utils/vkPixel';

const dataLayer = typeof window !== 'undefined' ? window.dataLayer : null;
interface ProductCardProps {
    product: Product,
}

const ProductCard = (props: ProductCardProps) => {
  const {
    Name, isFixed, IsVegan, IsSpicy,
    SmallDescription, Image, Weight, id, iikoID,
    Price, GroupOfMods, categoryTimeConstrain,
    categories,
  } = props.product;
  const { state, dispatch } = useContext(Context);
  const [cardClicked, handleCardClick] = useState(false);

  // Make the local and global state true when card is clicked
  const handleClick = (e) => {
    e.stopPropagation();

    handleCardClick(true);

    vkPixelCustomizeProduct();
    ecommerceOnProductModalOpening(Price, Name);

    dispatch({ type: MODAL_OPENED, payload: !state.isModalOpened });
  };

  // Return to false state when overlay is clicked (global is false)
  if (!state.isModalOpened && cardClicked) {
    handleCardClick(false);
  }

  return (
    <div key={id} onClick={handleClick} className={ProductCardContainer}>
      <div className={ProductCardClass}>
        <div className={ProductCartImagetextContainer}>
          {Image && (
          <ProductCardImage
            categoryTimeConstrain={props.categoryTimeConstrain}
            categories={categories}
            image={Image}
            alt={Name}
          />
          )}
          <ProductCardText
            IsSpicy={IsSpicy}
            IsVegan={IsVegan}
            description={SmallDescription}
            name={Name}
            weight={Weight}
          />
        </div>
        <ProductCardBottom
          isFixed={isFixed}
          handleCardClick={handleClick}
          productData={props.product}
          categoryTimeConstrain={props.categoryTimeConstrain}
        />
        {cardClicked && (
        <ProductModal
          categories={categories}
          categoryTimeConstrain={props.categoryTimeConstrain}
          isOpen={state.isModalOpened}
          product={props.product}
        />
        )}
      </div>
    </div>

  );
};

export const getCategoriesWithTimeConstrains = (categories) => categories && categories
  .filter((val: CategoriesType) => val.TimeConstrain);

export default ProductCard;
