import ProductModalInfoModifsListItem from './ProductModalInfoModifsItem/productModalInfoModifsItem';
import { ProductModalInfoOptionsList as ProductModalInfoOptionsListClass } from './productModalInfoModifsItem.module.css';
import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';

interface ProductModalInfoOptionsListProps {
    setModifsSelected: () => void;
    groupOfMods?: {};
    mods?: {};
}

const ProductModalInfoModifsList = ({
  setModifsSelected,
  groupOfMods, mods, modifsSelected,
}: ProductModalInfoOptionsListProps) => (
  <FormGroup className={ProductModalInfoOptionsListClass}>
    {(mods && Array.isArray(mods)) && mods.map((val) => (
      <ProductModalInfoModifsListItem
        key={val.iikoModifID}
        id={val.iikoModifID}
        setModifsSelected={setModifsSelected}
        Name={val.Name}
        modifsSelected={modifsSelected}
        Price={val.Price}
      />
    ))}
    {(groupOfMods && Array.isArray(groupOfMods))
      && [].concat(...groupOfMods
        .map((val) => val.Mod))
        .map((val) => (
          <ProductModalInfoModifsListItem
            key={val.iikoModifID}
            id={val.iikoModifID}
            Name={val.Name}
            setModifsSelected={setModifsSelected}
            Price={val.Price}
            modifsSelected={modifsSelected}
          />
        ))}
  </FormGroup>
);

export default ProductModalInfoModifsList;
